<template>
  <div id="managementReviewPlan">
    <el-dialog
      :title="managementReviewPlanFormTitle"
      width="680px"
      :visible.sync="managementReviewPlanDialogVisible"
      :close-on-click-modal="false"
      @close="managementReviewPlanDialogClose"
    >
      <el-form
        ref="managementReviewPlanFormRef"
        :model="managementReviewPlanForm"
        :rules="managementReviewPlanFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="评审主持人" prop="reviewHost">
              <el-input v-model="managementReviewPlanForm.reviewHost" placeholder="请输入评审主持人" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="管理评审目的" prop="purpose">
              <el-input
                v-model="managementReviewPlanForm.purpose"
                placeholder="请输入管理评审目的"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="管理评审依据" prop="accordance">
              <el-input
                v-model="managementReviewPlanForm.accordance"
                placeholder="请输入管理评审依据"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="管理评审参加部门及人员" prop="deptPersonnel">
              <el-input
                v-model="managementReviewPlanForm.deptPersonnel"
                placeholder="请输入管理评审参加部门及人员"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="管理评审内容" prop="content">
              <el-input
                v-model="managementReviewPlanForm.content"
                placeholder="请输入管理评审内容"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="各部门管理评审准备工作要求" prop="workClaim">
              <el-input
                v-model="managementReviewPlanForm.workClaim"
                placeholder="请输入各部门管理评审准备工作要求"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="管理评审计划要求" prop="planClaim">
              <el-input
                v-model="managementReviewPlanForm.planClaim"
                placeholder="请输入管理评审计划要求"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="管理评审会议的流程安排" prop="processArrangement">
              <el-input
                v-model="managementReviewPlanForm.processArrangement"
                placeholder="请输入管理评审会议的流程安排"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="编辑人" prop="editor">
              <el-input v-model="managementReviewPlanForm.editor" placeholder="请输入编辑人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编辑日期" prop="editDate">
              <el-date-picker v-model="managementReviewPlanForm.editDate" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="审核人" prop="reviewer">
              <el-input v-model="managementReviewPlanForm.reviewer" placeholder="请输入审核人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="审核时间" prop="reviewDate">
              <el-date-picker v-model="managementReviewPlanForm.reviewDate" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准人" prop="approver">
              <el-input v-model="managementReviewPlanForm.approver" placeholder="请输入批准人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准日期" prop="approveDate">
              <el-date-picker v-model="managementReviewPlanForm.approveDate" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="managementReviewPlanDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="managementReviewPlanFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="评审主持人">
        <el-input v-model="searchForm.reviewHost" placeholder="请输入评审主持人" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="managementReviewPlanPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="reviewHost" label="评审主持人" />
      <el-table-column prop="purpose" label="管理评审目的" />
      <el-table-column prop="accordance" label="管理评审依据" />
      <el-table-column prop="deptPersonnel" label="管理评审参加部门及人员" />
      <el-table-column prop="content" label="管理评审内容" />
      <el-table-column prop="workClaim" label="各部门管理评审准备工作要求" />
      <el-table-column prop="planClaim" label="管理评审计划要求" />
      <el-table-column prop="processArrangement" label="管理评审会议的流程安排" />
      <el-table-column prop="editor" label="编辑人" />
      <el-table-column prop="editDate" label="编辑日期" />
      <el-table-column prop="reviewer" label="审核人" />
      <el-table-column prop="reviewDate" label="审核时间" />
      <el-table-column prop="approver" label="批准人" />
      <el-table-column prop="approveDate" label="批准日期" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="managementReviewPlanPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addManagementReviewPlan, deleteManagementReviewPlan, updateManagementReviewPlan, selectManagementReviewPlanInfo, selectManagementReviewPlanList } from '@/api/quality/managementReviewPlan'

export default {
  data () {
    return {
      managementReviewPlanDialogVisible: false,
      managementReviewPlanFormTitle: '',
      managementReviewPlanForm: {
        id: '',
        reviewHost: '',
        purpose: '',
        accordance: '',
        deptPersonnel: '',
        content: '',
        workClaim: '',
        planClaim: '',
        processArrangement: '',
        editor: '',
        editDate: '',
        reviewer: '',
        reviewDate: '',
        approver: '',
        approveDate: ''
      },
      managementReviewPlanFormRules: {
        reviewHost: [{ required: true, message: '评审主持人不能为空', trigger: ['blur', 'change']}]
      },
      managementReviewPlanPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        name: ''
      }
    }
  },
  created () {
    selectManagementReviewPlanList(this.searchForm).then(res => {
      this.managementReviewPlanPage = res
    })
  },
  methods: {
    managementReviewPlanDialogClose () {
      this.$refs.managementReviewPlanFormRef.resetFields()
      this.managementReviewPlanForm.id = ''
    },
    managementReviewPlanFormSubmit () {
      if (this.managementReviewPlanFormTitle === '管理评审计划详情') {
        this.managementReviewPlanDialogVisible = false
        return
      }
      this.$refs.managementReviewPlanFormRef.validate(async valid => {
        if (valid) {
          if (this.managementReviewPlanFormTitle === '新增管理评审计划') {
            await addManagementReviewPlan(this.managementReviewPlanForm)
          } else if (this.managementReviewPlanFormTitle === '修改管理评审计划') {
            await updateManagementReviewPlan(this.managementReviewPlanForm)
          }
          this.managementReviewPlanPage = await selectManagementReviewPlanList(this.searchForm)
          this.managementReviewPlanDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.managementReviewPlanFormTitle = '新增管理评审计划'
      this.managementReviewPlanDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteManagementReviewPlan(row.id)
        if (this.managementReviewPlanPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.managementReviewPlanPage = await selectManagementReviewPlanList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.managementReviewPlanFormTitle = '修改管理评审计划'
      this.managementReviewPlanDialogVisible = true
      this.selectManagementReviewPlanInfoById(row.id)
    },
    handleInfo (index, row) {
      this.managementReviewPlanFormTitle = '管理评审计划详情'
      this.managementReviewPlanDialogVisible = true
      this.selectManagementReviewPlanInfoById(row.id)
    },
    selectManagementReviewPlanInfoById (id) {
      selectManagementReviewPlanInfo(id).then(res => {
        this.managementReviewPlanForm.id = res.id
        this.managementReviewPlanForm.reviewHost = res.reviewHost
        this.managementReviewPlanForm.purpose = res.purpose
        this.managementReviewPlanForm.accordance = res.accordance
        this.managementReviewPlanForm.deptPersonnel = res.deptPersonnel
        this.managementReviewPlanForm.content = res.content
        this.managementReviewPlanForm.workClaim = res.workClaim
        this.managementReviewPlanForm.planClaim = res.planClaim
        this.managementReviewPlanForm.processArrangement = res.processArrangement
        this.managementReviewPlanForm.editor = res.editor
        this.managementReviewPlanForm.editDate = res.editDate
        this.managementReviewPlanForm.reviewer = res.reviewer
        this.managementReviewPlanForm.reviewDate = res.reviewDate
        this.managementReviewPlanForm.approver = res.approver
        this.managementReviewPlanForm.approveDate = res.approveDate
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectManagementReviewPlanList(this.searchForm).then(res => {
        this.managementReviewPlanPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectManagementReviewPlanList(this.searchForm).then(res => {
        this.managementReviewPlanPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectManagementReviewPlanList(this.searchForm).then(res => {
        this.managementReviewPlanPage = res
      })
    },
    
  }
}
</script>

<style>
#managementReviewPlan .el-scrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>
